@tailwind base;
@tailwind components;
@tailwind utilities;

.css-nn3ywf-option {
  background: #2b8abc !important;
  color: white;
}

/* Hide scrollbars but keep scrolling for all elements */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
/* For WebKit-based browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.placeholder-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  left: 0;
  right: 0;
}

.placeholder-text {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  animation: scroll 20s linear infinite;
  color: #a7a7a7;
  font-size: 12px;
  animation-delay: 4s;
  animation-timing-function: linear;
}
.hidden-text {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}
.css-nn3ywf-option {
  background: #2b8abc !important;
  color: white;
}

/*Remove Microsoft Edge Default Eye icon*/
input[type='password']::-ms-reveal {
  display: none;
}

/* Hide scrollbar in Firefox */
.scrollbar-hide {
  scrollbar-width: none;
}
/* Hide scrollbar in WebKit-based browsers */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.custom-swiper-container {
  position: relative;
  overflow: hidden;
}
.custom-swiper .swiper-slide {
  opacity: 0;
}

.custom-swiper .swiper-slide-prev {
  margin-top: 30px;
  background-color: white;
  transform: scale(0.9) !important;
  opacity: 1;
  border-radius: 20px;
}
.custom-swiper .swiper-slide-next {
  margin-top: 30px;
  background-color: white;
  transform: scale(0.9) !important;
  opacity: 1;
  border-radius: 20px;
}
.custom-swiper .swiper-slide-active {
  padding: 30px 0px;
  background-color: transparent;
  transform: scale(1.1) !important;
  transition: transform 0.5s ease-out !important;
  opacity: 1;
}

.custom-swiper-container .arrow {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #033e80;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-swiper-container .arrow.arrow-left {
  left: 20px;
}
.custom-swiper-container .arrow.arrow-right {
  right: 10px;
}

/* Mobile View Adjustments */
@media (max-width: 1200px) {
  .custom-swiper-container .arrow {
    width: 40px;
    height: 40px;
  }
  .custom-swiper .swiper-slide-active {
    padding-top: 20px;
    transform: scale(1) !important;
    transition: transform 0.5s ease-out !important;
    animation: pop-up 1s ease-out;
  }
  .custom-swiper .swiper-slide {
    opacity: 1;
  }
}

.toast {
  position: fixed;
  top: 25px;
  right: 25px;
  background: #fff;
  border-radius: 4px;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.3);
  z-index: 1023;
  animation:
    slideInRight 0.3s ease-in-out forwards,
    fadeOut 0.5s ease-in-out forwards 4.9s;
  transform: translateX(110%);
}

.toast-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toast.toast-info {
  background: #fff;
}

.toast.toast-info .toast-progress {
  background-color: #3498db;
}

@keyframes slideInRight {
  0% {
    transform: translateX(110%);
  }

  75% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(110%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes toastProgress {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}
@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.toast-progress {
  width: 100%;
  animation-fill-mode: forwards;
}
